import { PageType } from 'context/AppContext';
import { AdSlotName } from 'types/ads';

export enum SiteType {
  Desktop = 'desktop',
  Mobile = 'mobile',
  iOS = 'iosphone',
  Android = 'androidphone',
}

export type AdPosition = {
  getName: () => string;
  containsAd: () => boolean;
};

export type MoltenBundle = {
  getClient: () => {
    setSiteType: (type: SiteType) => void;
    setZone: (zone: string) => void;
    setKeywords: (keywords: Array<string>) => void;
    setIsIndexPage: (isIndex: boolean) => void;
    reloadAds: () => void;
  };
  cmd: Array<() => void>;
  pushArray: (adslotIds: Array<string>) => void;
  finalize: () => void;
  push: (adSlotId: string) => void;
  Video: {
    getSXPKeysAsync: () => Promise<string>;
  };
  registerAdPositionLoadedHandler: (callback: (position: AdPosition) => void) => void;
};

declare global {
  interface Window {
    MoltenBundle: MoltenBundle;
  }
}

type InitGujAdParams = {
  readonly siteType: SiteType;
  readonly adZone: string;
  readonly keywords?: Array<string>;
  readonly pageType: PageType;
};

const initialize = ({ siteType, adZone, keywords, pageType }: InitGujAdParams) => {
  window.MoltenBundle = window.MoltenBundle ?? ({ cmd: [] } as unknown as MoltenBundle);

  window.MoltenBundle.cmd.push(() => {
    const gujAdClient = (window.MoltenBundle as MoltenBundle).getClient();
    gujAdClient.setSiteType(siteType);
    gujAdClient.setZone(adZone);
    if (keywords) {
      gujAdClient.setKeywords(keywords);
    }
    gujAdClient.setIsIndexPage(pageType === PageType.Overview);
  });
};

export const pushAdSlot = (adSlotId: AdSlotName) => {
  if (adSlotId && window?.MoltenBundle?.cmd !== undefined) {
    window.MoltenBundle.cmd.push(() => window.MoltenBundle?.push(adSlotId.toLowerCase()));
  }
};

export const AdAllianceLib = {
  initialize,
  pushAdSlot,
};
